import api from "./api";
import { CustomerInfoState } from "../components/dealerZone/Service/ServiceRepair";
import { UserObj } from "../GlobalTypes";

async function getUser(id: string) {
  return await api.get("/api/userInfo", {
    params: {
      id,
      page: "Service",
    },
  });
}

async function getSignature(
  timestamp: any,
  snNumber: number,
  imgNumber: number,
) {
  return await api.get("/api/getSignature", {
    params: {
      timestamp,
      snNumber,
      imgNumber,
    },
  });
}

interface UploadURLS {
  u1: string;
  u2: string;
  u3: string;
}

async function submitService(
  snNumber: number,
  uploadURLs: UploadURLS,
  customerInfo: CustomerInfoState,
  data: Partial<UserObj>,
  team: number,
  sendEmail?: boolean,
) {
  return await api.post("/api/submitServiceRepair", {
    snNumber,
    u1: uploadURLs.u1,
    u2: uploadURLs.u2,
    u3: uploadURLs.u3,
    info: customerInfo,
    dealer: data.dealer,
    dealerRep: data.dealerRep,
    dealerPhone: data.phone,
    dealerEmail: data.email,
    team,
    sendCustomerEmail: sendEmail,
  });
}

async function editService(
  team: number,
  sendEmail: boolean,
  uploadURLs: UploadURLS,
  customerInfo: CustomerInfoState,
  id: string,
  dealerInfo?: Partial<UserObj>,
) {
  if (dealerInfo) {
    return await api.put("/api/editServiceRepair", {
      u1: uploadURLs.u1,
      u2: uploadURLs.u2,
      u3: uploadURLs.u3,
      info: customerInfo,
      dealerInfo,
      id,
      sendEmail,
      team,
    });
  } else {
    return await api.put("/api/editServiceRepair", {
      u1: uploadURLs.u1,
      u2: uploadURLs.u2,
      u3: uploadURLs.u3,
      info: customerInfo,
      id,
      sendEmail,
    });
  }
}

async function setAppointment(
  serviceId: string,
  appointment: string | Date,
  sendEmail: boolean,
) {
  return await api.put("/api/setAppointment", {
    serviceId,
    appointment,
    sendEmail,
  });
}

async function getServiceRepairs(
  page: number,
  rowsPerPage: number,
  searchTerm: string,
  searchBy: string,
) {
  try{
    return await api.get("/api/getServiceRepairs", {
      params: {
        pageNumber: page,
        rowsPerPage,
        searchTerm,
        searchBy,
      },
    });
  }catch(err){
    console.log(err)
  }
}

async function postRepairStatus(selected: string[], orderOrCompleted: string) {
  return await api.put("/api/changeRepairStatus", {
    selected,
    orderOrCompleted,
  });
}

async function getServiceNumber() {
  return await api.get("/api/getSN");
}

async function getCalendarDates(month: number, year: number) {
  return await api.get("/api/getCalendarDates", {
    params: { month, year },
  });
}

async function searchCalSN(snNumber: number) {
  return await api.post("/api/searchCalendarSN", { snNumber });
}

async function assignTeam(snNumber: number, team: number) {
  return await api.post("/api/assignTeam", { snNumber, team });
}

async function uploadServiceImage(formData: FormData) {
  return await api.post("/api/uploadService", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

async function postAptChangeCalendar(
  id: string,
  appointment: Date | string,
  calendarYear: number,
  calendarMonth: number,
  calendarDay: number,
  sendEmail?: boolean,
) {
  return await api.post("/api/postAppointmentCalendar", {
    id,
    appointment,
    calendarYear,
    calendarMonth,
    calendarDay,
    sendEmail,
  });
}

async function postCompletedChangeCalendar(
  ids: string[],
  calendarYear: number,
  calendarMonth: number,
  calendarDay: number,
) {
  return await api.post("/api/setCompleted", {
    ids,
    calendarYear,
    calendarMonth,
    calendarDay,
  });
}

export {
  postCompletedChangeCalendar,
  uploadServiceImage,
  assignTeam,
  searchCalSN,
  getCalendarDates,
  editService,
  submitService,
  getSignature,
  getUser,
  getServiceRepairs,
  postRepairStatus,
  getServiceNumber,
  setAppointment,
  postAptChangeCalendar,
};
