import React, { useMemo, FC } from "react";
import { Typography } from "@mui/material";
import { Image } from "cloudinary-react";
import ReusableAccordian from "./ReusableAccordion";
import Choices from "./Choices";
import {
  screenPocketsImages,
  pocketsSwingImages,
  cornerScreenPocketsImages,
} from "../initialValues/screens";
import { useStore } from "../store/store";
import { useAccordion } from "../store/accordion";
import {
  singlePocket,
  doublePocketOptions,
  pocketDouble,
} from "../initialValues/screens";
import { RowLayout, ColLayout } from "../util/accordianStyles";

interface ScreenPocketProps {
  isDoubleSelected: boolean;
  oneSideOddConfig: boolean;
  handlePocketLayout: (label: string, click: string, show: boolean) => void;
  defaultPocketWide: () => void;
  displayedPocketImg: string;
  doorType: string;
}

const ScreenPocketAccordian: FC<ScreenPocketProps> = ({
  isDoubleSelected,
  oneSideOddConfig,
  handlePocketLayout,
  defaultPocketWide,
  displayedPocketImg,
  doorType,
}) => {
  const addToQuote = useStore((state) => state.addToQuote);

  const screenPocket = useStore((state) => state.quote.screenPocket);

  const screenPocketVal =
    screenPocket && screenPocket.replace(" (upcharge applies)", "");

  const material = useStore((state) => state.quote.material);
  const cornerType = useStore((state) => state.quote.cornerType);

  const handleAccordClick = useAccordion((state) => state.handleAccordClick);
  const expandedPocket = useAccordion((state) => state.expanded[26].expanded);

  const isCornerUnit = doorType?.includes("Corner");

  const handleClick = (label: string, type: string, disabled: boolean) => {
    if (disabled) return;

    const checked = label === screenPocket;

    const isDouble = type === "double";
    const isDoubleNoDefault = type === "doubleNoDefault";

    if (checked) {
      (isDoubleNoDefault || isDouble) &&
        handlePocketLayout(label, "secondClick", true);
      if (isDouble && label === "Add Double Pocket") {
        defaultPocketWide();
      } else {
        addToQuote({ screenPocket: "" });
      }
    } else {
      (isDoubleNoDefault || isDouble) &&
        handlePocketLayout(label, "firstClick", true);
      addToQuote({ screenPocket: label });
    }
  };

  const filteredPocketImg = useMemo(() => {
    if (!displayedPocketImg || doorType === "Slider") return;

    if (isCornerUnit) {
      return cornerScreenPocketsImages.find(
        (img) =>
          img.label === displayedPocketImg && img.cornerType === cornerType,
      );
    }

    const images = doorType.includes("Bi-fold")
      ? screenPocketsImages
      : pocketsSwingImages;

    return images.find((img) => img.label === displayedPocketImg);
  }, [displayedPocketImg, doorType, cornerType]);

  return (
    <ReusableAccordian
      expanded={expandedPocket}
      handleAccordClick={handleAccordClick}
      accordionName="screenPocket"
      heading="Screen Pocket"
      secondaryHeading="Add a screen pocket?"
      colOrRow="col"
      required={false}
    >
      <ColLayout style={{ marginLeft: 40 }}>
        <Typography variant="subtitle1">
          *upcharge applies for each pocket added
        </Typography>

        {isDoubleSelected && oneSideOddConfig && (
          <Typography variant="subtitle1">
            **Must have a screen pocket on daily door side to avoid blocking
            handle hardware
          </Typography>
        )}

        <RowLayout>
          {oneSideOddConfig &&
            isDoubleSelected &&
            !isCornerUnit &&
            pocketDouble.map((state) => (
              <div
                key={state.id}
                onClick={() =>
                  handleClick(state.label, "double", state.disabled || false)
                }
              >
                <Choices
                  label={state.label}
                  variant={
                    state.label === "Add Pocket" ||
                    state.label === screenPocketVal
                      ? "filled"
                      : "outlined"
                  }
                  checkMark={
                    state.label === "Add Pocket" ||
                    state.label === screenPocketVal
                      ? true
                      : false
                  }
                  disabled={state.disabled || false}
                  wide={true}
                />
              </div>
            ))}
          {!isDoubleSelected &&
            singlePocket.map((state) => (
              <div
                key={state.id}
                onClick={() =>
                  handleClick(state.label, "single", state.disabled || false)
                }
              >
                <Choices
                  label={state.label}
                  variant={state.label === screenPocket ? "filled" : "outlined"}
                  checkMark={state.label === screenPocket}
                  disabled={state.disabled || false}
                  wide={true}
                />
              </div>
            ))}
          {isCornerUnit &&
            isDoubleSelected &&
            doublePocketOptions.map((state) => (
              <div
                key={state.id}
                onClick={() =>
                  handleClick(
                    state.label,
                    "doubleNoDefault",
                    state.disabled || false,
                  )
                }
              >
                <Choices
                  label={state.label}
                  variant={
                    state.label === screenPocketVal ? "filled" : "outlined"
                  }
                  checkMark={state.label === screenPocketVal}
                  disabled={state.disabled || false}
                  wide={true}
                />
              </div>
            ))}
          {!isCornerUnit &&
            isDoubleSelected &&
            !oneSideOddConfig &&
            doublePocketOptions.map((state) => (
              <div
                key={state.id}
                onClick={() =>
                  handleClick(
                    state.label,
                    "doubleNoDefault",
                    state.disabled || false,
                  )
                }
              >
                <Choices
                  label={state.label}
                  variant={
                    state.label === screenPocketVal ? "filled" : "outlined"
                  }
                  checkMark={state.label === screenPocketVal}
                  disabled={state.disabled || false}
                  wide={true}
                />
              </div>
            ))}
        </RowLayout>
      </ColLayout>

      <RowLayout>
        {displayedPocketImg && filteredPocketImg?.label && (
          <RowLayout>
            <ColLayout style={{ marginRight: 20 }}>
              {material === "All Wood" ? (
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId={filteredPocketImg.imgWood}
                />
              ) : (
                <Image
                  cloudName="ag-millworks"
                  secure="true"
                  publicId={filteredPocketImg.img}
                />
              )}
              <Typography variant="caption">*As viewed from above</Typography>
            </ColLayout>

            <ColLayout style={{ marginLeft: 20 }}>
              <img src={filteredPocketImg.img2} alt="pocket" />
              <Typography variant="caption">
                *As viewed from exterior
              </Typography>
            </ColLayout>
          </RowLayout>
        )}
      </RowLayout>
    </ReusableAccordian>
  );
};

export default ScreenPocketAccordian;
