import React, {
  useState,
  useEffect,
  FC,
  SetStateAction,
  Dispatch,
} from "react";
import getModalStyle from "../../../util/getModalStyle";
import {
  Typography,
  Button,
  Modal,
  TextField,
  TextareaAutosize,
  Divider,
  Stack,
  Chip,
  IconButton,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import {
  AttachFile as AttachIcon,
  Send as SendIcon,
  Close as CloseIcon,
  AddCircle as AddIcon,
  CheckCircle as CheckIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import api from "../../../api/api";
import { StyledModalDiv } from "../../../util/accordianStyles";
import { styled } from "@mui/material/styles";

const StyledTextArea = styled(TextareaAutosize)({
  width: 400,
  height: 100,
  padding: 10,
  maxWidth: 500,
  minWidth: 200,
  maxHeight: 400,
  minHeight: 100,
});

const StyledDivCol = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledDivRowStart = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 5,
  width: "100%",
});

const StyledDivRowEnd = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: 140,
  paddingRight: 20,
});

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

interface CustomerEmailProps {
  customerEmail: string;
  dealerEmail?: string;
  fileName: string;
  open: boolean;
  handleClose: () => void;
  blob: Blob | null;
  url: string | null;
  type?: string;
}

const CustomerEmailModal: FC<CustomerEmailProps> = ({
  customerEmail,
  dealerEmail,
  fileName,
  open,
  handleClose,
  blob,
  url,
  type,
}) => {
  const [modalStyle] = useState(() => getModalStyle(50, 50));

  const [to, setTo] = useState<string[]>([]);

  const [newTo, setNewTo] = useState("");

  const [showToTextBox, setShowToTextBox] = useState(false);

  const [cc, setCC] = useState<string[]>([]);

  const [newCC, setNewCC] = useState("");

  const [showCCTextBox, setShowCCTextBox] = useState(false);

  const [subject, setSubject] = useState("AG Millworks Quote");

  const [emailBody, setBody] = useState("");

  const [filename, setFilename] = useState("");

  const [editFile, setEditFile] = useState(false);

  const [load, setLoad] = useState(false);

  const [error, setError] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);

  const handleOpenSnack = () => setOpenSnack(true);
  const handleCloseSnack = () => setOpenSnack(false);

  const handleChangeFilename = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilename(e.target.value);
  };

  const showTo = () => {
    if (to.length > 5) {
      return alert("Can't have more than 6 recipients");
    }

    setShowToTextBox(true);
  };

  const showCC = () => {
    if (cc.length > 5) {
      return alert("Can't have more than 6 recipients");
    }

    setShowCCTextBox(true);
  };

  const addCC = () => {
    const trimmed = newCC.trim();
    if (!trimmed) {
      return;
    }
    if (!validateEmail(trimmed)?.length) {
      return alert("Must be a valid email address");
    }
    setCC((prev) => [...prev, trimmed]);
    setNewCC("");
    setShowCCTextBox(false);
  };

  const removeCC = (deleteEmail: string) => {
    setCC((prev) => prev.filter((email) => email !== deleteEmail));
  };

  const addRecipient = () => {
    const trimmed = newTo.trim();
    if (!trimmed) {
      return;
    }
    if (!validateEmail(trimmed)?.length) {
      return alert("Must be a valid email address");
    }
    setTo((prev) => [...prev, trimmed]);
    setNewTo("");
    setShowToTextBox(false);
  };

  const allowEnterTo = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      addRecipient();
    }
  };

  const allowEnterCC = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      addCC();
    }
  };

  const removeRecipient = (deleteEmail: string) => {
    setTo((prev) => prev.filter((email) => email !== deleteEmail));
  };

  const handleChange =
    (setter: Dispatch<SetStateAction<string>>) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setter(event.target.value);
    };

  const handleSend = async () => {
    if (!to?.length) {
      return alert("Please provide at least 1 email recipient");
    }
    setLoad(true);

    try {
      if (blob === null) throw new Error("Blob is null");

      const form = new FormData();
      form.append("file", blob);
      form.append("emailBody", emailBody);
      form.append("to", to as any);
      form.append("cc", cc as any);
      form.append("subject", subject);
      form.append("filename", `${filename}.pdf`);

      const url =
        type === "insights" ? "/api/insights/sendInsightsPDF" : "/api/sendCustomerPDF";

      await api.post(url, form, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setLoad(false);
      setError(false);
      handleOpenSnack();
      handleClose();
    } catch (err) {
      console.log(err);
      setLoad(false);
      setError(true);
    }
  };

  useEffect(() => {
    setTo(customerEmail ? [customerEmail] : []);
    setCC(dealerEmail ? [dealerEmail] : []);
    setNewCC("");
    setNewTo("");
    setLoad(false);
    setError(false);
    setSubject(
      type === "insights" ? "AG Millworks Insights" : "AG Millworks Quote",
    );
    setBody("");
    setEditFile(false);
    setFilename(fileName.replace(".pdf", ""));
  }, [open]);

  const body = (
    <StyledModalDiv style={{ ...modalStyle, width: 600 }}>
      <StyledDivCol>
        <StyledDivCol style={{ padding: 10, marginBottom: 15 }}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {type === "insights"
              ? "Email Insights"
              : "Email Quote Directly to Customer"}
          </Typography>
          {!type && (
            <Typography variant="caption">
              *email will be sent from no-reply@agmillworks.com
            </Typography>
          )}
          <Divider
            style={{ width: "100%", paddingTop: 5 }}
            orientation="horizontal"
          />
        </StyledDivCol>
        <StyledDivRowStart>
          <StyledDivRowEnd style={{ width: 100 }}>
            <Typography style={{ fontWeight: "bold" }}>To:</Typography>
          </StyledDivRowEnd>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            style={{ minWidth: 240 }}
          >
            {to.length
              ? to.map((email, key) => (
                  <Chip
                    key={key}
                    label={email}
                    variant="outlined"
                    onDelete={() => removeRecipient(email)}
                  />
                ))
              : null}
            {showToTextBox ? (
              <StyledDivRow style={{ paddingBottom: 10, width: "100%" }}>
                <TextField
                  inputProps={{ style: { paddingTop: 2, paddingBottom: 2 } }}
                  size="small"
                  value={newTo}
                  onChange={handleChange(setNewTo)}
                  style={{ maxWidth: 225 }}
                  onKeyDown={allowEnterTo}
                />
                <IconButton onClick={addRecipient}>
                  <CheckIcon color="success" />
                </IconButton>
              </StyledDivRow>
            ) : (
              <IconButton onClick={showTo}>
                <AddIcon color="primary" />
              </IconButton>
            )}
          </Stack>
        </StyledDivRowStart>

        <StyledDivRowStart>
          <StyledDivRowEnd style={{ width: 100 }}>
            <Typography style={{ fontWeight: "bold" }}>CC:</Typography>
          </StyledDivRowEnd>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            style={{ minWidth: 240 }}
          >
            {cc.length
              ? cc.map((email, key) => (
                  <>
                    {email === dealerEmail ? (
                      <Chip
                        key={key}
                        label={email}
                        variant="outlined"
                        color={"cyan" as "primary"}
                      />
                    ) : email === dealerEmail ? (
                      <Chip
                        key={key}
                        label={email}
                        variant="outlined"
                        color={"cyan" as "primary"}
                      />
                    ) : (
                      <Chip
                        key={key}
                        label={email}
                        variant="outlined"
                        color={"cyan" as "primary"}
                        onDelete={() => removeCC(email)}
                      />
                    )}
                  </>
                ))
              : null}
            {showCCTextBox ? (
              <StyledDivRow style={{ paddingBottom: 10, width: "100%" }}>
                <TextField
                  inputProps={{ style: { paddingTop: 2, paddingBottom: 2 } }}
                  size="small"
                  value={newCC}
                  onChange={handleChange(setNewCC)}
                  style={{ maxWidth: 225 }}
                  onKeyDown={allowEnterCC}
                />
                <IconButton onClick={addCC}>
                  <CheckIcon color="success" />
                </IconButton>
              </StyledDivRow>
            ) : (
              <IconButton onClick={showCC}>
                <AddIcon color="primary" />
              </IconButton>
            )}
          </Stack>
        </StyledDivRowStart>

        <StyledDivRowStart style={{ paddingTop: 10 }}>
          <StyledDivRowEnd style={{ width: 100 }}>
            <Typography style={{ fontWeight: "bold" }}>Subject:</Typography>
          </StyledDivRowEnd>

          <TextField
            size="small"
            value={subject}
            onChange={handleChange(setSubject)}
            style={{ maxWidth: 225 }}
          />
        </StyledDivRowStart>

        <StyledDivRowStart style={{ paddingTop: 10 }}>
          <StyledDivRowEnd>
            <AttachIcon />
            <Typography style={{ fontWeight: "bold" }}>Attachment:</Typography>
          </StyledDivRowEnd>

          <StyledDivRow>
            {!editFile ? (
              <>
                <a
                  href={url || ""}
                  style={{ textDecoration: "none", color: "black" }}
                  download={`${filename}.pdf`}
                >
                  <Typography>{filename}.pdf</Typography>
                </a>

                <Tooltip title="Change File Name" placement="top">
                  <IconButton onClick={() => setEditFile(true)}>
                    <EditIcon color={"cyan" as "primary"} />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <StyledDivRow style={{ paddingBottom: 10, width: "100%" }}>
                <TextField
                  inputProps={{ style: { paddingTop: 2, paddingBottom: 2 } }}
                  size="small"
                  value={filename}
                  onChange={handleChangeFilename}
                  style={{ maxWidth: 320 }}
                  onKeyDown={(e) =>
                    e.key === "Enter" ? setEditFile(false) : null
                  }
                />
                <IconButton onClick={() => setEditFile(false)}>
                  <CheckIcon color="success" />
                </IconButton>
              </StyledDivRow>
            )}
          </StyledDivRow>
        </StyledDivRowStart>

        <StyledDivRow style={{ paddingTop: 20 }}>
          <StyledTextArea
            value={emailBody}
            onChange={handleChange(setBody)}
            placeholder="Email Body..."
          />
        </StyledDivRow>

        {error && (
          <StyledDivRow style={{ padding: 10 }}>
            <Typography
              variant="subtitle1"
              style={{ color: "red", fontWeight: "bold" }}
            >
              A problem occured sending the email
            </Typography>
          </StyledDivRow>
        )}

        <StyledDivRow style={{ paddingTop: 40 }}>
          <Button
            color="inherit"
            onClick={handleClose}
            startIcon={<CloseIcon />}
            variant="contained"
            style={{ marginRight: 40 }}
            size="large"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSend}
            endIcon={<SendIcon />}
            style={{ marginLeft: 40 }}
            disabled={load}
            size="large"
          >
            {load ? "Sending..." : "Send"}
          </Button>
        </StyledDivRow>
      </StyledDivCol>
    </StyledModalDiv>
  );

  return (
    <>
      <Snackbar
        open={openSnack}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Your email has been sent!
        </Alert>
      </Snackbar>

      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </>
  );
};

export default CustomerEmailModal;
