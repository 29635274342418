import React, { useState, useEffect } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, useParams } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import { styled } from "@mui/material/styles";

const StyledDivLoading = styled("div")({
  fontSize: "24px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const StyledDivRow = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledDivCol = styled("div")({
  backgroundColor: "#efefef",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "100vh",
  minHeight: "100vh",
});

const StyledDivCol2 = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid black",
  borderRadius: 10,
  padding: 20,
  backgroundColor: "white",
});

const StyledFormControl = styled(FormControl)({
  marginTop: "10px",
  paddingBottom: 10,
});

const StyledResetDiv = styled("div")({
  marginTop: 50,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [updated, setUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const [minimumChar, setMinChar] = useState("");

  const params: { token?: string; ip?: string } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get("/api/reset", {
          params: {
            resetPasswordToken: params.token,
          },
        });
        if (response.data.message === "password reset link a-ok") {
          setEmail(response.data.username);
          setUpdated(false);
          setIsLoading(false);
          setError(false);
        }
      } catch (error: any) {
        console.log(error.response.data);
        setUpdated(false);
        setIsLoading(false);
        setError(true);
      }
    })();
  }, [params.token]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var value = event.target.value;
    var name = event.target.name;
    name === "password" && setPassword(value);
  };

  const updatePassword = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password.length < 6) {
      setMinChar("Password must be at least 6 characters");
      return;
    }

    try {
      const response = await axios.put("/api/updatePasswordViaEmail", {
        email,
        password,
        resetPasswordToken: params.token,
        ipToRemove: params.ip || null,
      });

      if (response.data.message === "password updated") {
        setUpdated(true);
        setError(false);
      } else {
        setUpdated(false);
        setError(true);
      }
    } catch (error: any) {
      console.log(error.response.data);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event: React.MouseEvent<any>) =>
    event.preventDefault();

  if (error) {
    return (
      <StyledDivLoading>
        <Typography variant="body2" style={{ padding: 10 }}>
          Problem resetting password.
        </Typography>
        <Typography variant="body2" style={{ padding: 10 }}>
          Your link may be expired. Please send another reset link.
        </Typography>
        <Link style={{ textDecoration: "none", margin: 10 }} to="/">
          <Button variant="outlined" color="primary">
            Go to Login
          </Button>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/forgotPassword">
          <Button variant="outlined" color="primary">
            Resend Link
          </Button>
        </Link>
      </StyledDivLoading>
    );
  }
  if (isLoading) {
    return (
      <StyledDivRow>
        <StyledDivLoading>Loading User Data...</StyledDivLoading>
      </StyledDivRow>
    );
  }
  return (
    <StyledDivCol>
      <StyledDivCol2>
        <Typography variant="h4">Set New Password</Typography>
        <Typography>Enter a new password for your account.</Typography>

        <form
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          className="password-form"
          onSubmit={updatePassword}
        >
          <StyledFormControl variant="outlined">
            <InputLabel htmlFor="standard-adornment-password">
              New Password
            </InputLabel>
            <Input
              id="standard-adornment-password"
              type={showPassword ? "text" : "password"}
              value={password}
              name="password"
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </StyledFormControl>
          <Button
            style={{ marginTop: 20 }}
            variant="outlined"
            color="primary"
            type="submit"
          >
            Update Password
          </Button>
          {minimumChar && (
            <Typography style={{ color: "red" }}>{minimumChar}</Typography>
          )}
        </form>

        {updated && (
          <StyledResetDiv>
            <Typography variant="body2" style={{ color: "green" }}>
              Your password has been successfully reset, please try logging in
              again.
            </Typography>
            <Link style={{ textDecoration: "none" }} to="/">
              <Button variant="outlined" color="primary">
                Go to Login
              </Button>
            </Link>
          </StyledResetDiv>
        )}
      </StyledDivCol2>
    </StyledDivCol>
  );
};

export default ResetPassword;
