import api from "./api";
import {
  UnitObj,
  AccordionObj,
  RequiredObj,
  AddonObj,
} from "../util/quoteCartStorage";
import { ChoiceObj } from "../components/CartCheck/CartCheckModal";

async function getUser(userId: string) {
  return await api.get("/api/userInfo", {
    params: {
      id: userId,
    },
  });
}

async function getQuote(userId: string, quoteNumber: number) {
  return await api.get("/api/history/getQuote", {
    params: {
      userId,
      quoteNumber,
    },
  });
}

async function postUnit(
  saved: UnitObj,
  id: string,
  localStorageState: string,
  required: RequiredObj[] | string[],
  accordionControl: AccordionObj[],
  itemizedAddons: AddonObj[],
  itemizedAddonsUnfactored: AddonObj[],
  ignoreUnitCheck?: boolean,
) {
  return await api.put("/api/addUnit", {
    saved,
    id,
    localStorageState,
    required,
    accordionControl,
    itemizedAddons,
    itemizedAddonsUnfactored,
    ignoreUnitCheck,
  });
}

async function postDuplicate(
  unitId: string,
  quoteId: string,
  orderIndex: number,
) {
  return await api.put("/api/addUnit", {
    unitId,
    id: quoteId,
    isDuplicate: true,
    orderIndex,
  });
}

async function postUnitUpdate(
  saved: UnitObj,
  quoteId: string,
  unitId: string,
  itemized: AddonObj[],
  itemizedUnfactored: AddonObj[],
  localStorageState: string,
  required: RequiredObj[] | string[],
  accordionControl: AccordionObj[],
  orderIndex: number,
  ignoreUnitCheck?: boolean,
) {
  return await api.put("/api/editQuote", {
    saved,
    quoteId,
    unitId,
    itemizedAddons: itemized,
    itemizedAddonsUnfactored: itemizedUnfactored,
    localStorageState,
    required,
    accordionControl,
    orderIndex,
    ignoreUnitCheck,
  });
}

async function postQtyUpdate(
  quoteId: string,
  unitId: string,
  newQuantity: number,
) {
  return await api.put("/api/updateQuantity", {
    quoteId,
    unitId,
    newQuantity,
  });
}

async function postAdminFactor(quoteId: string, adminFactor: string) {
  return await api.put("/api/adminFactorChange", {
    quoteId,
    adminFactor,
  });
}

async function getPricing(URL: string, store: UnitObj, quoteId: string) {
  return await api.post(`/api/${URL}`, {
    saved: store,
    quoteId,
  });
}

async function deleteUnit(quoteId: string, unitIds: string[]) {
  return await api.put("/api/history/deleteUnit", {
    quoteId,
    unitIds,
  });
}

async function postCustomGlass(
  quoteId: string,
  unitId: string,
  customGlass: boolean,
) {
  return await api.post("/api/customGlass", {
    quoteId,
    unitId,
    customGlass,
  });
}

async function postIgnoreCartCheck(quoteId: string) {
  return await api.post("/api/ignoreCartCheck", {
    id: quoteId,
  });
}

async function getIgnoreCartCheck(quoteId: string) {
  return await api.get("/api/withCartCheck", {
    params: {
      id: quoteId,
    },
  });
}

async function getIgnoreUnitCheck(quoteId: string, unitId: string) {
  return await api.get("/api/withUnitCheck", {
    params: {
      quoteId,
      unitId,
    },
  });
}

async function cartCheckUpdate(
  quoteId: string,
  cartObj: ChoiceObj,
  userId: string,
) {
  return await api.post("/api/cartCheckUpdate", {
    quoteId,
    cartObj,
    userId,
  });
}

async function addKD(quoteId: string, unitId: string) {
  return await api.post("/api/addKD", {
    quoteId,
    unitId,
  });
}

async function removeKD(quoteId: string, unitId: string) {
  return await api.post("/api/removeKD", {
    quoteId,
    unitId,
  });
}

export {
  addKD,
  removeKD,
  getPricing,
  postAdminFactor,
  postQtyUpdate,
  postUnitUpdate,
  postDuplicate,
  postUnit,
  getQuote,
  getUser,
  deleteUnit,
  postCustomGlass,
  postIgnoreCartCheck,
  getIgnoreCartCheck,
  getIgnoreUnitCheck,
  cartCheckUpdate,
};
